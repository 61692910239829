/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Text, Subtitle, Title, Image } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="css-1blnhbm --parallax pb--50 pt--50" anim={null} name={"pf"} animS={null} style={{"minHeight":"100vh"}} layout={"l1"} parallax={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/158/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/158/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/158/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/158/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/158/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/158/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/158/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/158/fullscreen_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 pb--40 pt--40 flex--center" style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s7" anim={"2"} animS={"7"} style={{"maxWidth":800}}>
              
              <Text className="text-box" content={"<span style=\"color: rgb(255, 255, 255);\">   </span>"}>
              </Text>

              <Subtitle className="subtitle-box subtitle-box--style8 subtitle-box--center swpf--uppercase mt--25" style={{"maxWidth":914}} content={"<div><span style=\"color: var(--subtitle-color); letter-spacing: var(--subtitle-tracking); background-color: transparent;\">&nbsp;</span><br></div>"}>
              </Subtitle>

              <Title className="title-box ff--1 fs--220 mt--20" style={{"maxWidth":1172}} content={"<span style=\"color: rgb(255, 255, 255);\">2023</span><br>"}>
              </Title>

              <Subtitle className="subtitle-box subtitle-box--style8 subtitle-box--center swpf--uppercase mt--20" style={{"maxWidth":""}} content={"&nbsp;"}>
              </Subtitle>

              <Image alt={""} src={"https://cdn.swbpg.com/o/11212/3b208b5895ab48968e6cd15fc3f09918.svg"} sizes={"100vw"} style={{"maxWidth":312}} srcSet={""}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}